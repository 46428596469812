import img1 from '../images/common/icon_9.png';
import img2 from '../images/common/icon_10.png';
import img3 from '../images/common/icon_11.png';

const dataStep = [
    {
        id: 1,
        img: img1,
        title: 'Play-to-Earn',
        text: 'Engage in various activities and games aboard the spaceship to earn real cryptocurrency. Whether competing in swimming races, socializing in lounges, or trying your luck with slot machines, every action can reward you with valuable in-game currency and NFTs.'
    }, 
    {
        id: 2,
        img: img2,
        title: 'Staking/Mining',
        text: "Contribute to the spaceship's energy system by staking your cryptocurrency. Earn rewards based on your staked amount and duration, and participate in resource mining in the basement level to gather rare materials for upgrades and trading."
    }, 
    {
        id: 3,
        img: img3,
        title: 'Referral Rewards',
        text: 'Invite friends to join Astral Nomad and earn rewards for each new player who registers through your link. Form teams to complete special tasks and challenges, unlocking additional bonuses for you and your friends.'
    }, 

]

export default dataStep;