import {React , useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import routes from './pages';
import '../src/assets/fonts/font-awesome.css'
import Header from './components/header';
import Footer from './components/footer';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

// 1. Your WalletConnect Cloud project ID
const projectId = '27a477d0a93e5ca93c39837a8ccf75ec'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}
const testnet = {
  chainId: 11155111,
  name: 'Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.etherscan.io/',
  rpcUrl: 'https://sepolia.infura.io/v3/27a477d0a93e5ca93c39837a8ccf75ec'
}
// 3. Create a metadata object
const metadata = {
  name: 'MyWeb3',
  description: 'AppKit Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp:true
})

function App() {
  useEffect(() => {
    AOS.init({
      duration : 2000
    }); 
  }, []);
  return (
    <>

    <Header />

    <Routes>

        {
            routes.map((data,idx) => (
                <Route key={idx} path={data.path} element={data.component} exact />
            ))
        }

    </Routes>

    <Footer />
</>
  );
}

export default App;