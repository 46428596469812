import img1 from '../images/slider/Furore.png';
import img2 from '../images/slider/icon_1.png';
import img3 from '../images/slider/icon_2.png';
import img4 from '../images/slider/icon_3.png';

const dataBanner = [
    {
        id: 1,
        heading: 'Welcome to the Astral Nomad, the ultimate frontier in Spaceship Web3 Gaming!',
        sub_heading: 'Explore a futuristic spaceship filled with exciting games and social activities. Earn real cryptocurrency as you play and connect with other players. Join us now to experience the future of gaming!',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 2,
        heading: 'Welcome to the Astral Nomad, the ultimate frontier in Spaceship Web3 Gaming!',
        sub_heading: 'Explore a futuristic spaceship filled with exciting games and social activities. Earn real cryptocurrency as you play and connect with other players. Join us now to experience the future of gaming!',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    }
]

export default dataBanner;
