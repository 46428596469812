
const dataRoadmap = [
    {
        id: 1,
        time: 'Launch and Initial Setup',
        text1: 'Finalize the core game mechanics and features',
        text2: 'Launch the official Astral Nomad website',
        text3: 'Start building a vibrant community on social media platforms',
        status: 'Conduct a closed beta test to gather feedback and make necessary adjustments'
    }, 
    {
        id: 2,
        time: 'Expansion and Enhancement',
        text1: 'Officially launch Astral Nomad to the public',
        text2: 'Introduce new game rooms and entertainment facilities',
        text3: 'Implement the referral rewards system to encourage player growth',
        status: 'Initiate a comprehensive marketing campaign to attract new players'
    },
    {
        id: 3,
        time: 'Integration and Optimization',
        text1: 'Launch the staking and mining features for players to earn rewards',
        text2: 'Introduce the DAO voting system for player-driven game updates',
        text3: 'Establish partnerships with other blockchain projects and gaming platforms',
        status: 'Continuously optimize game performance and user experience'
    },
    {
        id: 4,
        time: 'Long-term Growth and Sustainability',
        text1: 'Provide regular content updates and new challenges',
        text2: 'Explore cross-chain compatibility with other blockchain networks',
        text3: 'Empower players with more governance capabilities through the DAO',
        status: 'Expand the Astral Nomad ecosystem with new features, partnerships, and community-driven initiatives'
    },
   


]

export default dataRoadmap;