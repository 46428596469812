import React , { useState  } from 'react';
import PropTypes from 'prop-types';

import { Pagination , A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles.scss';
import Countdown from '../../countdown';


Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const {data} = props;
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [dataTitle] = useState(
        {
            title: 'Game scene ',
            title_2: ' in the spaceship'
        }
    )
    
    const handleSlideChange = (swiper) => {
        setCurrentSlideIndex(swiper.activeIndex);
    };
    const renderContent = () => {
        switch (currentSlideIndex) {
            case 0:
                return <>
                    <h4>Interactive Adventure Rooms</h4>
                    <p className="desc">
                        Discover a variety of interactive adventure rooms within the spaceship, each
                        designed for different activities and experiences. From social lounges and
                        entertainment zones on the first floor to specialized game rooms in the
                        basement, there's always something new to explore and enjoy.
                    </p>
                </>
            case 1:
                return <>
                    <h4>Death Match Betting Room</h4>
                    <p className="desc">
                        Enter the Death Match Betting Room, where players can bet on the outcomes of intense virtual battles. Predict the winners, place your bets, and stand a chance to win substantial rewards. Engage in multiplayer modes for added excitement and interaction.
                    </p>
                </>
            default:return <></>
        }
    };
    
    return (
        <section className="tf-section project" style={{marginTop:'0px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                        <h2 className="title">
                        {dataTitle.title}<br className="show-destop"/> {dataTitle.title_2}
                        </h2>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="project-wrapper">
                        <div className="image-wrapper" data-aos="fade-in" data-aos-duration="1000">
                            <div className="slider-1">
                            <Swiper
                                    modules={[Pagination, A11y]}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        pagination={{ clickable: true }}
                                    onSlideChange={handleSlideChange}
                                    >
                                {
                                    data.slice(0,2).map(item => (
                                        <SwiperSlide key={item.id} item={item}>
                                            <img src={item.img} alt="Risebot" />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content_inner" data-aos="fade-left" data-aos-duration="1200">
                                <div className="wrapper">
                                    {renderContent()}

                                    {/*<ul className="price"> */}
                                    {/*    <li>*/}
                                    {/*        <span>Price: $0.4</span>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*       <span>Total sales: $4720</span>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                {/* <h6 className="featured_title">Sale end in</h6> */}
                                <div className="featured-countdown">
                                <div className="js-countdown">
                                    {/* {<Countdown />} */}
                                </div>
                                    {/* <ul className="desc">
                                        <li>day</li>
                                        <li>hou</li>
                                        <li>min</li>
                                        <li>sec</li>
                                    </ul> */}
                                </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Project;
