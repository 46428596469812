import React from 'react';

import PageTitle from '../components/pagetitle';

import CTA from '../components/cta';
import icon from '../assets/images/common/down.svg'




function Roadmap(props) {
    return (
        <div className='inner-page'>
            {<PageTitle title='RoadMap' />}

            <section className="tf-section roadmap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="roadmap-wrapper-style2" data-aos="fade-up" data-aos-duration="1200">
                        <div className="left">
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Launch and Initial Setup</h6>
                                    <ul>
                                        <li>Finalize the core game mechanics and features.</li>
                                        <li>Launch the official Astral Nomad website.</li>
                                        <li>Start building a vibrant community on social media platforms.</li>
                                        <li>Conduct a closed beta test to gather feedback and make necessary adjustments.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt=""/>
                                </div>
                                <div className="content">
                                    <h6 className="date">Integration and Optimization</h6>
                                    <ul>
                                        <li>Launch the staking and mining features for players to earn rewards.</li>
                                        <li>Introduce the DAO voting system for player-driven game updates.</li>
                                        <li>Establish partnerships with other blockchain projects and gaming
                                            platforms.
                                        </li>
                                        <li>Continuously optimize game performance and user experience.</li>
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="roadmap-box-style">*/}
                            {/*    <div className="icon">*/}
                            {/*        <img src={icon} alt=""/>*/}
                            {/*    </div>*/}
                            {/*    <div className="content">*/}
                            {/*        <h6 className="date">April 16, 2022</h6>*/}
                            {/*        <ul>*/}
                            {/*            <li>Cras molestie ullamcorper augue nec pulvinar</li>*/}
                            {/*            <li>Nam mollis sapien ut sapien gravida sollicitudin</li>*/}
                            {/*            <li>Mauris vel nisl quis dolor accumsan luctus</li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="roadmap-box-style">*/}
                            {/*    <div className="icon">*/}
                            {/*        <img src={icon} alt=""/>*/}
                            {/*    </div>*/}
                            {/*    <div className="content">*/}
                            {/*        <h6 className="date">April 16, 2022</h6>*/}
                            {/*        <ul>*/}
                            {/*            <li>Cras molestie ullamcorper augue nec pulvinar</li>*/}
                            {/*            <li>Nam mollis sapien ut sapien gravida sollicitudin</li>*/}
                            {/*            <li>Mauris vel nisl quis dolor accumsan luctus</li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="right">
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt=""/>
                                </div>
                                <div className="content">
                                    <h6 className="date">Expansion and Enhancement</h6>
                                    <ul>
                                        <li>Officially launch Astral Nomad to the public.</li>
                                        <li>Introduce new game rooms and entertainment facilities.</li>
                                        <li>Implement the referral rewards system to encourage player growth.</li>
                                        <li>Initiate a comprehensive marketing campaign to attract new players.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt=""/>
                                </div>
                                <div className="content">
                                    <h6 className="date">Long-term Growth and Sustainability</h6>
                                    <ul>
                                        <li>Provide regular content updates and new challenges.</li>
                                        <li>Explore cross-chain compatibility with other blockchain networks.</li>
                                        <li>Empower players with more governance capabilities through the DAO.</li>
                                        <li>Expand the Astral Nomad ecosystem with new features, partnerships, and
                                            community-driven initiatives.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="roadmap-box-style">*/}
                            {/*    <div className="icon">*/}
                            {/*        <img src={icon} alt=""/>*/}
                            {/*    </div>*/}
                            {/*    <div className="content">*/}
                            {/*        <h6 className="date">April 16, 2022</h6>*/}
                            {/*        <ul>*/}
                            {/*            <li>Cras molestie ullamcorper augue nec pulvinar</li>*/}
                            {/*            <li>Nam mollis sapien ut sapien gravida sollicitudin</li>*/}
                            {/*            <li>Mauris vel nisl quis dolor accumsan luctus</li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            {<CTA />}

        </div>
    );
}

export default Roadmap;
