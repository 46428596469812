

const dataFaq = [
    {
        id: 1,
        title: 'How do I start playing Astral Nomad?',
        content: 'To start playing, visit our official website, create an account, and follow the setup instructions. You will need a compatible cryptocurrency wallet to participate in the game.'
    },
    {
        id: 2,
        title: 'What is the play-to-earn system?',
        content: 'The play-to-earn system allows players to earn real cryptocurrency by participating in various activities and games within the spaceship. Rewards can be earned through competitions, social interactions, and completing tasks.'
    },
    {
        id: 3,
        title: 'How does staking work in Astral Nomad?',
        content: 'Staking involves locking up your cryptocurrency to support the spaceship\'s energy system. In return, you earn rewards based on the amount staked and the duration. This helps maintain and upgrade the spaceship\'s facilities.'
    },
    {
        id: 4,
        title: 'What are referral rewards and how can I earn them?',
        content: 'Referral rewards are incentives given to players who invite friends to join Astral Nomad. When a new player registers and starts playing through your referral link, both you and the new player receive rewards, such as in-game currency or NFTs.'
    },
    {
        id: 5,
        title: 'Is Astral Nomad secure?',
        content: 'Yes, Astral Nomad is built on the Base Chain, a secure and scalable Layer 2 solution. We prioritize the safety and privacy of our players, employing robust security measures to protect your data and assets.'
    },
    {
        id: 6,
        title: 'How can I upgrade my spaceship?',
        content: 'You can upgrade your spaceship by earning and using in-game currency. Upgrades include adding new modules, enhancing existing facilities, and personalizing your spaceship with unique NFT decorations. Some upgrades can also be decided through the DAO voting system.'
    },


]

export default dataFaq;
