import React , { useState , useEffect } from 'react';

import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import Button from '../button/index';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png'

import { useDisconnect, useSwitchNetwork, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react'

// const MyButtonStyle = {
//     background: '#798DA3',
//     color: white,
//     &:hover {
//        background: '#86FF00',
//        color: '#000000',
//     }
// };
const Header = () => {


    // 4. Use modal hook
    const { open } = useWeb3Modal();
    const {disconnect} = useDisconnect();
    const {address,chainId,isConnected} = useWeb3ModalAccount();
    const {switchNetwork} = useSwitchNetwork();
    const [hiddenAddress, setHiddenAddress] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    
    

    useEffect(() => {
        console.log({address,chainId,isConnected})
        if(isConnected){
            setHiddenAddress(hideMiddlePart(address));
        }

    },[isConnected,address]);
    const hideMiddlePart = (str, visibleStart = 4, visibleEnd = 4) => {
        if (str.length <= visibleStart + visibleEnd) {
          return str; 
        }
        const start = str.slice(0, visibleStart);
        const end = str.slice(-visibleEnd);
        const middle = '...';
        return `${start}${middle}${end}`;
      };
   

    const handleClick = () => {
       
        if(isConnected){
            disconnect();
        }else{
            open();
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        console.log(isHovered);
        
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
        console.log(isHovered);
      };

    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
             <div className="container">
            <div id="site-header-inner">
                <div className="header__logo" style={{width:200}}>
                    <NavLink to="/"><img src={logo} alt="Risebot" /></NavLink>
                </div>
                <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                    <ul id="menu-primary-menu" className="menu">

                        {
                            menus.map((data,idx) => (
                                <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}

                                >
                                    <Link to={data.links}>{data.name}</Link>
                                    {
                                        data.namesub &&
                                        <ul className="sub-menu">
                                            {
                                                data.namesub.map((submenu) => (
                                                    <li key={submenu.id} className="menu-item"><NavLink to={submenu.links} onClick={handleDropdown}>{submenu.sub}</NavLink></li>
                                                ))
                                            }
                                        </ul>
                                    }

                                </li>
                            ))
                        }
                    </ul>
                </nav>

                <Button></Button>
                {isConnected?<w3m-button/>:<Button title={isConnected&&isHovered?'Disconnect':isConnected?hiddenAddress:'Connect'} path='/' addclass='style1'
                 onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>}
                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
            </div>
        </div>
        </header>


    );
}

export default Header;
